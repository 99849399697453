@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  // overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

.giftCarSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
}
.giftCarSliderImg {
  height: 150px;
}
.giftCardsWrapper {
  width: 550px;
  height: auto;
  padding: 2rem 0rem 2rem 0.1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  overflow: auto !important;
}
.giftCardsWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.giftCardsWrapper::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.giftCardsWrapper::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
