.screen2_navbar {
  box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.116);
  height: 55px;
}
.screen_2_body {
  width: 180px;
  height: 180px;
  border-radius: 150px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.151);
  display: block;
}

/* div {
  animation-timing-function: ease;
  transition-duration: 0.3s;
} */

.screen_2_body_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* align-content: flex; */
}

.screen_2_body2 {
  /* margin-left: 400px; */
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%); */
}
/* .screen_2_body2_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    align-content: flex-start;  
}  */
.screen2_upload_section {
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, -0%);
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.445);
  display: flex;
  background-color: #8a8b8b;
}
.screen2_upload_section .list_style {
  border-radius: 25px;
  width: 500px;
}
@media (max-width: 800px) {
  .screen2_upload_section .list_style {
    width: 99%;
  }
  .custom-file-input::before {
    margin-left: 33%;
  }
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Upload Photos";
  color: black;
  display: absolute;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
  font-size: 11pt;
  width: 10%;
}

.add_more_imgs {
  transition-duration: 0.2s;
  cursor: pointer;
  background-color: #ffffff;
  right: 5%;
  position: fixed;
  border-radius: 100%;
  bottom: 15%;
  color: #1976d2;
  border: 0;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.185);
  padding: 10px;
  cursor: pointer;
  z-index: 999;
}

.add_more_imgs svg {
  font-size: 100px;
  color: rgb(51, 124, 234);
  width: 30px;
  height: 30px;
}

.add_more_imgs input {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
}

@media (max-width: 600px) {
  .add_more_imgs {
    bottom: 20%;
  }
}
@media (max-height: 800px) {
  .add_more_imgs {
    bottom: 26%;
  }
}
@media (max-height: 700px) {
  .add_more_imgs {
    bottom: 28%;
  }
}

.add_more_imgs:hover {
  transform: scale(1.09);
  transition-duration: 0.2s;
  cursor: pointer;
}
/* .add_more_imgs{

  } */
.custom-file-input2 {
  color: transparent;
  width: 30px;
}
.custom-file-input2::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input2::before {
  content: "";
  color: black;
  outline: none;
  cursor: pointer;
  font-size: 11pt;
  width: 0px;
}

/* .sssss{
  top: 30%;
  position: absolute;
  left: 40%;
} */

.section_2_img_frame {
  /* border: none;
  z-index: 10;
  margin-top: -25px;
  height: 379.7499694824219px;
  width: 391.75px;
  border-radius: 0px;
  background: linear-gradient(234.4deg, #ffffff -1.25%, #ededed 117.2%);
  display: flex;
  align-items: center;
  justify-content: center; */
}

.mainImage {
  width: 90%;
  height: 93%;
  /* border: 30px solid gainsboro; */
  object-fit: cover;
  margin: auto;
}

.reactEasyCrop_Container {
  /* width: 90%;
  height: 93%;
  border: 30px solid gainsboro; */
  position: relative !important;
}

/* .reactEasyCrop_Container {
  position: fixed !important;
  margin: 123px 40px 40px 170px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px !important;
  width: 220px !important;
  background-color: #1976d2;
} */
/* .reactEasyCrop_CropArea {
  height: 320px !important;
  width: 300px !important;
} */

.section_2_img_frame2 {
  border: 3px solid #ccc;
  background: #eee;
  margin: auto;
  border: 20px #f7f7f7 solid;
  overflow: hidden;
}
.btn-remove {
  border: 0;
  background-color: transparent;
  margin-left: -3px;
  position: relative;
  /* z-index: 99999999; */
  margin-top: -20px;
}
.screen_2_editing_section {
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 300px;
  min-width: max-content;
  align-items: center;
  z-index: 999;
  gap: 1rem;
}
@media (max-height: 800px) {
  .screen_2_editing_section {
    bottom: 19%;
  }
}

.screen_2_select_filter_div {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 600px;
  height: 190px;
  border-radius: 14px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.205);
  background-color: white;
  z-index: 999;
}
@media (max-width: 700px) {
  .screen_2_select_filter_div {
    width: 100%;
  }
}

.screen_2_select_filter_div .img_filter {
  height: 70px;
  width: 70px;
  /* background-color: grey; */
}
.scroll_x {
  overflow-x: auto !important;
}
::-webkit-scrollbar {
  width: 2px;
  height: 7px;
  position: absolute;
  margin-top: 20px;
  margin-bottom: -20px;
  padding: -20px;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 10px;
  cursor: grab;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #747474b2;
}

.screen_2_select_size_div {
  position: fixed;
  bottom: 13%;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 900px;
  border-radius: 14px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.205);
  background-color: white;
  overflow-y: scroll !important;
  z-index: 1;
}
@media (max-width: 700px) {
  .screen_2_select_size_div {
    width: 100%;
  }
}

.checkOut_bar {
  position: fixed;
  /* z-index: 50000; */
  bottom: 0%;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 10px 0 5px 1px rgba(0, 0, 0, 0.219);
  z-index: 99;
}
.checkOut_btn {
  background-color: #1976d2;
  width: 20%;
  height: 65%;
  color: white;
  font-weight: bold;
  font-size: large;
  border-radius: 9px;
  border: 0;
  z-index: -1;
}

@media (max-width: 800px) {
  .checkOut_btn {
    width: 90%;
    height: 55%;
  }
}

.check_out_list_parent {
  margin-top: 35px;
}
.check_out_list_parent .list_check_out {
  list-style: none;
  font-weight: 600;
  color: #1976d2;
  font-size: 17px;
  padding-left: 13px;
}

.add_address_popup {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.205);
  height: auto;
  width: 600px;
  background-color: white;
  border-radius: 15px;
  font-weight: bold;
  z-index: 9999999;
}
.address_form {
  background-color: #7474741a;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px;
  margin-bottom: 18px;
}

.address_form span {
  flex: 0.3;
}
.address_form div {
  border-bottom: 1px solid gainsboro;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_form input {
  height: 52px;
  background-color: transparent;
  border: 0;
  flex: 0.8;
}

.address_form select {
  height: 52px;
  background-color: transparent;
  flex: 0.8;
  outline: none;
  -webkit-appearance: button;
  -webkit-border-radius: 2px;

  border: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.address_form select option:nth-child(1) {
  display: none;
}
.address_form input:focus {
  outline: none !important;
}
/* .address_form span {
  padding-top: 20px;
} */

/* Addpayment popup */

.add_payment_popup_parent {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.205);
  height: auto;
  width: 600px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  font-weight: bold;
}

.credit_card_popup_parent {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.205);
  height: 400px;
  width: 600px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  font-weight: bold;
}

.align_frame_centerr {
  position: relative;
  height: 464.7400207519531px;
  width: 476.08001708984375px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  pointer-events: none;
}

.ant-drawer-header {
  display: none !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-modal-wrap {
  z-index: 9999999 !important;
}

.orderDetail p {
  margin-bottom: 5px;
}

* {
  overflow-x: hidden;
}

.addBtn {
  position: relative;
  top: unset;
  left: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none;
  margin-bottom: 20px;
}

.addBtn + h5 {
  width: 170px;
  text-align: center;
}

.addBtnContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cropModal {
  width: 700px !important;
  height: 570px !important;
}

.ant-modal-content {
  height: 100%;
}

.modalWindow .mainImage {
  width: 100%;
  cursor: move !important;
}

.modalWindow .align_frame_centerr {
  margin: 0 !important;
  cursor: move;
}

body {
  overflow: auto;
}

@media (max-width: 767px) {
  body {
    overflow: auto;
  }
  .imagesContainer {
    padding-left: 28px !important;
    padding-right: 28px !important;
    margin-top: 10%;
  }

  .imagesContainer .align_frame_centerr {
    width: 100% !important;
  }

  .ant-drawer-content-wrapper,
  .add_address_popup {
    width: 100% !important;
  }

  .add_address_popup {
    top: 50%;
  }

  .address_form {
    margin: 10px !important;
  }

  .screen_2_editing_section {
    width: 270px;
  }

  .ant-modal {
    width: 100% !important;
  }
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-drawer {
  z-index: 99999;
}

.react-transform-wrapper {
  height: 100% !important;
}

.ant-slider {
  height: 21px !important;
  margin: 10px 15px 10px !important;
  padding: 8px 0 !important ;
  overflow: visible;
}

.viewport {
  width: 92%;
  height: 93%;
  z-index: 99;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
